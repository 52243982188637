import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import countriesData from '../data/countries.json';
import statesData from '../data/states.json';
import citiesData from '../data/cities.json';
import API_URL from '../config/config';
import validator from 'validator';
import { categories, subcategories } from './CategoryDropdown';

const africanCountries = countriesData.filter(country => country.region === 'Africa');

const initialFormData = {
  name: '',
  description: '',
  street: '',
  city: '',
  country: '',
  state: '',
  category: '',
  subcategory: '',
  website: '',
  facebook: '',
  instagram: '',
  tiktok: '',
  twitter: '',
  whatsapp: '',
  phone: ''
};

function BusinessManager() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [isCityDisabled, setIsCityDisabled] = useState(true);

  const handleCountryChange = useCallback((selectedOption) => {
    const country = selectedOption ? selectedOption.label : '';
    const countryId = selectedOption ? selectedOption.value : '';
    setFormData((prevData) => ({ ...prevData, country, state: '', city: '' }));
    setIsStateDisabled(true);
    setIsCityDisabled(true);

    const states = statesData
      .filter(state => state.country_id === countryId)
      .map(state => ({
        label: state.name,
        value: state.id
      }));
    setStateOptions(states);
    setCityOptions([]); // Reset city options when country changes

    // Enable state dropdown if there are states available
    setIsStateDisabled(states.length === 0);
  }, []);

  const handleStateChange = useCallback((selectedOption) => {
    const state = selectedOption ? selectedOption.label : '';
    const stateId = selectedOption ? selectedOption.value : '';
    setFormData((prevData) => ({ ...prevData, state, city: '' }));
    setIsCityDisabled(true);

    const cities = citiesData
      .filter(city => city.state_id === stateId)
      .map(city => ({
        label: city.name,
        value: city.id
      }));
    setCityOptions(cities);

    // Enable city dropdown if there are cities available
    setIsCityDisabled(cities.length === 0);
  }, []);

  const handleCityChange = (selectedOption) => {
    const city = selectedOption ? selectedOption.label : '';
    setFormData((prevData) => ({ ...prevData, city }));
  };

  const handleCategoryChange = useCallback((selectedOption) => {
    const category = selectedOption ? selectedOption.label : '';
    setFormData((prevData) => ({ ...prevData, category, subcategory: '' }));

    const subcategoriesList = subcategories[category] || [];
    const subcategoryOptions = subcategoriesList.map(subcategory => ({
      label: subcategory,
      value: subcategory
    }));
    setSubcategoryOptions(subcategoryOptions);
  }, []);

  const handleSubcategoryChange = (selectedOption) => {
    const subcategory = selectedOption ? selectedOption.label : '';
    setFormData((prevData) => ({ ...prevData, subcategory }));
  };

  useEffect(() => {
    const countries = africanCountries.map(country => ({
      label: country.name,
      value: country.id
    }));
    setCountryOptions(countries);

    const categoriesOptions = categories.map(category => ({
      label: category,
      value: category
    }));
    setCategoryOptions(categoriesOptions);

    if (id) {
      fetch(`${API_URL}/api/business/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.json())
      .then(data => {
        const filteredData = Object.keys(data).reduce((acc, key) => {
          if (initialFormData.hasOwnProperty(key)) {
            acc[key] = data[key];
          }
          return acc;
        }, {});
        setFormData(filteredData);

        const selectedCountry = countries.find(country => country.label === data.country);
        if (selectedCountry) {
          handleCountryChange({ label: data.country, value: selectedCountry.value });
        }

        const selectedCategory = categoriesOptions.find(category => category.label === data.category);
        if (selectedCategory) {
          handleCategoryChange({ label: data.category, value: selectedCategory.value });
        }
      })
      .catch(error => {
        console.error('Error fetching business data:', error);
        alert('Failed to fetch business data');
      });
    }
  }, [id, handleCountryChange, handleCategoryChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateInput = () => {
    const newErrors = {};

    // Validation for required fields
    if (!formData.name) newErrors.name = 'Please fill in name.';
    if (!formData.description) newErrors.description = 'Please fill in description.';
    if (!formData.street) newErrors.street = 'Please fill in street.';
    if (!formData.city) newErrors.city = 'Please fill in city.';
    if (!formData.country) newErrors.country = 'Please select country.';
    if (!formData.state) newErrors.state = 'Please select state.';
    if (!formData.category) newErrors.category = 'Please select category.';
    if (!formData.phone || !validator.isMobilePhone(formData.phone, 'any', { strictMode: true })) {
      newErrors.phone = 'Please enter a valid phone number.';
    }

    // Validation for optional fields
    if (formData.website && !validator.isURL(formData.website)) {
      newErrors.website = 'Please enter a valid URL for your website.';
    }
    if (formData.facebook && !validator.isURL(formData.facebook)) {
      newErrors.facebook = 'Please enter a valid URL for your Facebook profile.';
    }
    if (formData.instagram && !validator.isURL(formData.instagram)) {
      newErrors.instagram = 'Please enter a valid URL for your Instagram profile.';
    }
    if (formData.tiktok && !validator.isURL(formData.tiktok)) {
      newErrors.tiktok = 'Please enter a valid URL for your TikTok profile.';
    }
    if (formData.twitter && !validator.isURL(formData.twitter)) {
      newErrors.twitter = 'Please enter a valid URL for your Twitter profile.';
    }
    if (formData.whatsapp && !validator.isMobilePhone(formData.whatsapp, 'any', { strictMode: true })) {
      newErrors.whatsapp = 'Please enter a valid WhatsApp number with country code (e.g., 2348012345678).';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('No token found, cannot register business');
      alert('You are not logged in. Please log in to register a business.');
      return;
    }

    if (!validateInput()) {
      return; // Display errors and prevent form submission
    }

    const formDataToSend = { ...formData };
  
    // Remove empty optional fields
    Object.keys(formDataToSend).forEach(key => {
        if (formDataToSend[key] === '') {
        formDataToSend[key] = null;
        }
    });

    const apiUrl = id ? `${API_URL}/api/business/${id}` : `${API_URL}/api/business`;
    const method = id ? 'PUT' : 'POST';

    fetch(apiUrl, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to save business');
      }
      return response.json();
    })
    .then(data => {
      console.log('Business saved successfully:', data);
      alert('Business saved successfully!');
      navigate('/dashboard');
    })
    .catch(error => {
      console.error('Error saving business:', error);
      alert('Error saving business: ' + error.message);
    });
  };

  return (
    <div className="container mt-5">
      <h2>{id ? 'Edit Your Business' : 'Register Your Business'}</h2>
      <form onSubmit={handleSubmit} className="row g-3">
        {Object.keys(initialFormData).map(key => (
          key !== 'country' && key !== 'state' && key !== 'city' && key !== 'category' && key !== 'subcategory' && (
            <div key={key} className="col-md-6">
              <label htmlFor={key} className="form-label">
                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')} {['name', 'description', 'street', 'city', 'country', 'state', 'category', 'phone'].includes(key) && <span className="text-danger">*</span>}
              </label>
              <input
                type={key.includes('phone') || key.includes('whatsapp') ? 'tel' : 'text'}
                className={`form-control ${errors[key] ? 'is-invalid' : ''}`}
                id={key}
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required={['name', 'description', 'street', 'city', 'country', 'state', 'category', 'phone'].includes(key)}
              />
              {errors[key] && <div className="invalid-feedback">{errors[key]}</div>}
              {['name', 'description', 'street', 'city', 'country', 'state', 'category', 'phone'].includes(key) && (
                <small className="form-text text-muted">This field is mandatory.</small>
              )}
              {['facebook', 'instagram', 'tiktok', 'twitter', 'website'].includes(key) && (
                <small className="form-text text-muted">
                  Please enter the full URL for your {key}.
                </small>
              )}
              {key === 'whatsapp' && (
                <small className="form-text text-muted">
                  Please enter your WhatsApp number with country code without spaces or special characters (e.g., +2348012345678).
                </small>
              )}
              {key === 'phone' && (
                <small className="form-text text-muted">
                  Please enter your phone number with country code without spaces or special characters (e.g., +2348012345678).
                </small>
              )}
            </div>
          )
        ))}
        <div className="col-md-6">
          <label htmlFor="country" className="form-label">Country: <span className="text-danger">*</span></label>
          <Select
            options={countryOptions}
            value={countryOptions.find(option => option.label === formData.country)}
            onChange={handleCountryChange}
          />
          {errors.country && <div className="invalid-feedback">{errors.country}</div>}
          <small className="form-text text-muted">This field is mandatory.</small>
        </div>
        <div className="col-md-6">
          <label htmlFor="state" className="form-label">State: <span className="text-danger">*</span></label>
          <Select
            options={stateOptions}
            value={stateOptions.find(option => option.label === formData.state)}
            onChange={handleStateChange}
            isDisabled={isStateDisabled}
          />
          {errors.state && <div className="invalid-feedback">{errors.state}</div>}
          <small className="form-text text-muted">This field is mandatory.</small>
        </div>
        <div className="col-md-6">
          <label htmlFor="city" className="form-label">City: <span className="text-danger">*</span></label>
          <Select
            options={cityOptions}
            value={cityOptions.find(option => option.label === formData.city)}
            onChange={handleCityChange}
            isDisabled={isCityDisabled}
          />
          {errors.city && <div className="invalid-feedback">{errors.city}</div>}
          <small className="form-text text-muted">This field is mandatory.</small>
        </div>
        <div className="col-md-6">
          <label htmlFor="category" className="form-label">Category: <span className="text-danger">*</span></label>
          <Select
            options={categoryOptions}
            value={categoryOptions.find(option => option.label === formData.category)}
            onChange={handleCategoryChange}
          />
          {errors.category && <div className="invalid-feedback">{errors.category}</div>}
          <small className="form-text text-muted">This field is mandatory.</small>
        </div>
        <div className="col-md-6">
          <label htmlFor="subcategory" className="form-label">Subcategory:</label>
          <Select
            options={subcategoryOptions}
            value={subcategoryOptions.find(option => option.label === formData.subcategory)}
            onChange={handleSubcategoryChange}
          />
          {errors.subcategory && <div className="invalid-feedback">{errors.subcategory}</div>}
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">{id ? 'Save Changes' : 'Submit'}</button>
        </div>
      </form>
    </div>
  );
}

export default BusinessManager;
