import React from 'react';

function DataPolicy() {
    return (
        <div className="container mt-5">
            <h2>Data Policy</h2>
            <p>
                Find A Biz Africa ("we," "us," or "our") is committed to protecting your privacy. This Data Policy explains how we collect, use, and share information when you use our services.
            </p>

            <h3>Information We Collect</h3>

            <p>We collect information to provide and improve our services. The types of information we collect include:</p>

            <h4>Business Owner Information:</h4>

            <ul>
                <li><b>Contact Information:</b> Name, email address, phone number to create and manage your business listing.</li>
                <li><b>Billing Information:</b> Payment details when you purchase additional services or features (if applicable).</li>
            </ul>

            <h4>User Information (People Searching for Businesses):</h4>

            <p>We do not collect personally identifiable information (PII) from users who are simply browsing or searching for businesses on our platform.</p>

            <h4>Usage Data:</h4>
            <ul>
                <li>We automatically collect information about your interactions with our website, such as the pages you visit, your search queries, and the businesses you view.</li>
            </ul>

            <h3>How We Use Your Information</h3>
            <ul>
                <li><b>Provide and Maintain Services:</b> To create and manage business listings, display search results, and enable communication between users and businesses.</li>
                <li><b>Business Analytics:</b> To provide business owners with insights into their listing performance (e.g., views, clicks).</li>
                <li><b>Communication:</b> To send you relevant updates, newsletters, or promotional offers (you can opt-out at any time).</li>
                <li><b>Improve Our Services:</b> To analyze usage patterns, troubleshoot issues, and develop new features.</li>
                <li><b>Prevent Fraud and Abuse:</b> To protect our platform and users from unauthorized access or malicious activities.</li>
            </ul>

            <h3>How We Share Your Information</h3>
            <p>
                We do not sell, rent, or trade your personal information to third parties. However, we may share your information with:
            </p>
            <ul>
                <li><b>Service Providers:</b> Trusted third-party companies that help us with website hosting, payment processing, or data analysis. These providers are obligated to protect your information.</li>
                <li><b>Legal Compliance:</b> When required by law or to protect our rights, safety, or property.</li>
            </ul>

            <h3>Data Security</h3>
            <p>
                We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. We use encryption and other security measures to safeguard your data.
            </p>

            <h3>Your Choices</h3>
            <ul>
                <li><b>Access and Update:</b> You can access and update your account information through your profile settings.</li>
                <li><b>Delete Account:</b> You can request to delete your account and associated data by contacting us.</li>
                <li><b>Opt-out:</b> You can unsubscribe from promotional emails using the link provided in the email or by adjusting your notification settings.</li>
            </ul>

            <h3>Changes to This Policy</h3>
            <p>We may update this Data Policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>

            <h3>Contact Us</h3>
            <p>If you have any questions or concerns about our Data Policy, please contact us via our contact form.</p>
        </div>
    );
}

export default DataPolicy;
