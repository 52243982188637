import React from 'react';
import MetaTags from './MetaTags';

function BusinessTips() {
    return (
        <div className="container mt-4">
            <MetaTags
                title="Find A Biz Africa - Listing Tips & Tools"
                description="Learn how to create the best listing for your African business and access our free toolkit."
                keywords="business listing tips, Africa, business tools, directory listing optimization"
            />

            <h2>Make Your Business Stand Out On - Find A Biz Africa</h2>
            <p>A complete and well-crafted listing will help more customers find you. Here's how to create a listing that shines.</p>
            
            <div className="listing-tips">
                <h3>Basic Information</h3>
                <ul>
                    <li><b>Business Name:</b> Use your official, registered business name.</li>
                    <li><b>Category:</b> Choose the category that best describes your main business activity.</li>
                    <li><b>Location:</b> Provide your full address so customers can easily find you.</li>
                    <li><b>Contact Information:</b> Include a phone number and email address where customers can reach you.</li>
                    <li><b>Website & Social Media:</b> Add links to your website and social media pages for further information.</li>
                </ul>

                <h3>Your Business Description</h3>
                <p>Tell potential customers what makes your business unique:</p>
                <ul>
                    <li><b>What do you do?</b> Clearly describe the products or services you offer.</li>
                    <li><b>What sets you apart?</b> Highlight your specialties, experience, or any awards or certifications you have.</li>
                    <li><b>Why should customers choose you?</b> Mention your strengths, such as quality, customer service, or unique offerings.</li>
                    <li><b>Use keywords:</b> Think like a customer! What words would they search to find a business like yours? Include those in your description.</li>
                </ul>
            </div>

            <hr className="section-divider" />

            <div className="faq-section">
                <h3>Frequently Asked Questions</h3>
                <div className="faq-item">
                    <h4>1. How do I edit my listing?</h4>
                    <p>You can easily edit your listing information by logging into your account and going to your Dashboard.</p>
                </div>
                <div className="faq-item">
                    <h4>2. Can I add photos or videos to my listing?</h4>
                    <p>Not at the moment, we will update you once this is available.</p>
                </div>
            </div>

            <h3>Business Toolkit</h3>
            <p>Get access to our free toolkit to help you manage and grow your business:</p>
            <ul>
                <li><a href="https://app.box.com/s/hw9adzvpmjqqlky3ditad5so0mzljlzo" target="_blank" rel="noopener noreferrer">Download the Toolkit</a></li> 
            </ul>
        </div>
    );
}

export default BusinessTips;
