import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import Select from 'react-select';
import { africanCountries } from '../data/africanCountries';
import axiosInstance from '../axiosInstance';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [country, setCountry] = useState(null);
  const [error, setError] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [timer, setTimer] = useState(0);
  const [channel, setChannel] = useState('whatsapp');
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const validateInput = () => {
    if (!validator.isEmail(email)) {
      return "Please enter a valid email address.";
    }
    if (!country) {
      return "Please select a country.";
    }
    const phoneWithoutLeadingZero = phone.startsWith('0') ? phone.slice(1) : phone;
    const phoneWithCode = `+${country.phone_code}${phoneWithoutLeadingZero}`;
    if (!validator.isMobilePhone(phoneWithCode, 'any', { strictMode: true })) {
      return `Please enter a valid phone number with the country code (+${country.phone_code}).`;
    }
    if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*'()[{}\]<>?/\\|`~;:"',.-_+=])[A-Za-z\d!@#$%^&*'()[{}\]<>?/\\|`~;:"',.-_+=]{8,}$/)) {
      return "Password must be at least 8 characters long and include at least one letter, one number, and one special character (!@#$%^&*'()[{}<>?/\\|`~;:\"',.-_+=).";
    }
       
    return '';
  };

  const handleRequestOtp = async () => {
    const validationError = validateInput();
    if (validationError) {
      setError(validationError);
      return;
    }
    try {
      const phoneWithoutLeadingZero = phone.startsWith('0') ? phone.slice(1) : phone;
      const response = await axiosInstance.post('/api/send-otp', {
        phone: `+${country.phone_code}${phoneWithoutLeadingZero}`,
        email,
        channel,
      });
      if (response.status === 200) {
        setOtpSent(true);
        setError('');
        setInfoMessage('OTP has been sent to your phone/whatsapp. Please enter it above to verify. If you do not receive the OTP within 1 minute, it will be sent to your email as a backup.');
        setTimer(60);

        // Start the fallback timer
        setTimeout(() => {
          if (!otpVerified) {
            handleFallbackToEmail();
          }
        }, 60000); // 60 seconds
      }
    } catch (error) {
      console.error('Error requesting OTP:', error);
      setError(error.response?.data?.message || 'Failed to send OTP');
    }
  };

  const handleFallbackToEmail = async () => {
    try {
      const response = await axiosInstance.post('/api/send-otp-email', { email });
      if (response.status === 200) {
        setInfoMessage('OTP has been sent to your email as a backup.');
      }
    } catch (error) {
      console.error('Error sending OTP via email:', error);
      setError('Failed to send OTP via email as a backup');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axiosInstance.post('/api/verify-otp', {
        email,
        otp,
      });
      if (response.status === 200) {
        setOtpVerified(true);
        setError('');
        setInfoMessage('OTP verified successfully. Please click on "Sign Up" to complete your registration.');
      } else {
        setError('Invalid OTP');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError(error.response?.data?.message || 'Failed to verify OTP');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!otpSent) {
      handleRequestOtp();
      return;
    }

    if (!otpVerified) {
      handleVerifyOtp();
      return;
    }

    try {
      const phoneWithoutLeadingZero = phone.startsWith('0') ? phone.slice(1) : phone;
      const response = await axiosInstance.post('/api/signup', {
        email, password, phone: `+${country.phone_code}${phoneWithoutLeadingZero}`, country: country.value
      });
      if (response.status === 201) {
        alert('Signup successful');
        navigate('/login');
      } else {
        setError(response.data.message || 'Signup failed');
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Signup failed due to system error');
    }
  };

  return (
    <div className="container mt-4">
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="form-group">
          <label>Email:</label>
          <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Country:</label>
          <Select
            options={africanCountries}
            value={country}
            onChange={setCountry}
            required
          />
        </div>
        {country && (
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="tel"
              className="form-control"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              placeholder="Enter phone number without leading zero"
              required
            />
            <small className="form-text text-muted">Do not include the leading zero. For example, if your number is 07012345678, enter 7012345678.</small>
          </div>
        )}
        <div className="form-group">
          <label>Password:</label>
          <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} required />
          <small className="form-text text-muted">Password must be at least 8 characters long and include at least one letter, one number, and at least one special character.</small>

        </div>
        {otpSent && !otpVerified && (
          <div className="form-group">
            <label>OTP:</label>
            <input type="text" className="form-control" value={otp} onChange={e => setOtp(e.target.value)} required />
            <button type="button" className="btn btn-link" onClick={handleRequestOtp} disabled={timer > 0}>
              Resend OTP {timer > 0 && `in ${timer} seconds`}
            </button>
          </div>
        )}
        <div className="form-group">
          <label>Channel:</label>
          <Select
            options={[
              { value: 'whatsapp', label: 'WhatsApp' },
              { value: 'sms', label: 'SMS' },
            ]}
            value={{ value: channel, label: channel === 'sms' ? 'SMS' : 'WhatsApp' }}
            onChange={option => setChannel(option.value)}
          />
        </div>
        {infoMessage && <div className="alert alert-info" role="alert">{infoMessage}</div>}
        {error && <div className="alert alert-danger" role="alert">{error}</div>}
        <button type="submit" className="btn btn-primary mt-4">{!otpSent ? 'Request OTP' : !otpVerified ? 'Verify OTP' : 'Sign Up'}</button>
      </form>
    </div>
  );
}

export default Signup;
