import axios from 'axios';
import API_URL from './config/config';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('axiosInstance: Added Authorization header:', config.headers['Authorization']);
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await axios.post(`${API_URL}/api/refresh-token`, {
          token: localStorage.getItem('token'),
        });
        if (response.status === 200) {
          localStorage.setItem('token', response.data.authToken);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.authToken;
          return axiosInstance(originalRequest);
        }
      } catch (err) {
        console.error('Error refreshing token:', err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
