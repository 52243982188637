import React, { useState } from 'react';

function SuggestionForm({ onSuggestionSubmit }) {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!message.trim()) {
            setError('Message is required');
            return;
        }

        try {
            await onSuggestionSubmit(message);
            setMessage('');
            setError('');
        } catch (err) {
            console.error('Error submitting suggestion:', err);
            setError('Failed to submit suggestion');
        }
    };

    return (
        <div className="card mb-4">
            <div className="card-body">
                <h2>Suggestion Box</h2>
                <p>Send us your business questions or pain points:</p>
                {error && <p className="alert alert-danger">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="suggestion">Your Suggestion:</label>
                        <textarea
                            id="suggestion"
                            className="form-control"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>
                    <button className="btn btn-primary" type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default SuggestionForm;
