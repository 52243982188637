import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import API_URL from '../config/config'; // Adjust the path as necessary

function BusinessAnalytics() {
    const { id } = useParams();
    const [metrics, setMetrics] = useState([]);
    const [dateRange, setDateRange] = useState({ start: '', end: '' });
    const [error, setError] = useState('');

    const fetchMetrics = useCallback(() => {
        fetch(`${API_URL}/api/business/${id}/metrics?start=${dateRange.start}&end=${dateRange.end}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch metrics');
            }
            return response.json();
        })
        .then(data => {
            setMetrics(data);
        })
        .catch(err => {
            console.error('Error fetching metrics:', err);
            setError(err.message || 'Unknown error');
        });
    }, [id, dateRange]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRange({ ...dateRange, [name]: value });
    };

    const handleFetchMetrics = () => {
        fetchMetrics();
    };

    return (
        <div className="container mt-5">
            <h2>Business Analytics</h2>
            {error && <p className="alert alert-danger">{error}</p>}
            <div className="row mb-3">
                <div className="col-md-6">
                    <label>Start Date:</label>
                    <input type="date" name="start" className="form-control" value={dateRange.start} onChange={handleDateChange} />
                </div>
                <div className="col-md-6">
                    <label>End Date:</label>
                    <input type="date" name="end" className="form-control" value={dateRange.end} onChange={handleDateChange} />
                </div>
            </div>
            <button className="btn btn-primary mb-3" onClick={handleFetchMetrics}>Retrieve Metrics</button>
            {metrics.length > 0 && (
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={metrics}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="search_views" fill="#1c2e11" name="Search Views">
                            <LabelList dataKey="search_views" position="top" />
                        </Bar>
                        <Bar dataKey="clicks" fill="#82ca9d" name="Clicks">
                            <LabelList dataKey="clicks" position="top" />
                        </Bar>
                        <Bar dataKey="website_clicks" fill="#ac8320" name="Website Clicks">
                            <LabelList dataKey="website_clicks" position="top" />
                        </Bar>
                        <Bar dataKey="facebook_clicks" fill="#3b5998" name="Facebook Clicks">
                            <LabelList dataKey="facebook_clicks" position="top" />
                        </Bar>
                        <Bar dataKey="instagram_clicks" fill="#e1306c" name="Instagram Clicks">
                            <LabelList dataKey="instagram_clicks" position="top" />
                        </Bar>
                        <Bar dataKey="tiktok_clicks" fill="#69c9d0" name="TikTok Clicks">
                            <LabelList dataKey="tiktok_clicks" position="top" />
                        </Bar>
                        <Bar dataKey="twitter_clicks" fill="#1da1f2" name="Twitter Clicks">
                            <LabelList dataKey="twitter_clicks" position="top" />
                        </Bar>
                        <Bar dataKey="whatsapp_clicks" fill="#25d366" name="WhatsApp Clicks">
                            <LabelList dataKey="whatsapp_clicks" position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

export default BusinessAnalytics;