import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';  // Corrected import to use useAuth

function Logout() {
    const { logout } = useAuth();  // Access logout using useAuth
    const navigate = useNavigate();

    useEffect(() => {
        logout();  // Call logout from context, which clears the user token and state
        navigate('/login');  // Redirect to the login page after logging out
    }, [logout, navigate]);  // Add dependencies here for useEffect to manage

    return null;  // Optionally, display a message or a spinner while logging out
}

export default Logout;

