import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from './MetaTags';

function FAQs() {
    return (
        <div className="container mt-5">
            <MetaTags
                title="Find A Biz Africa - FAQs for Business Owners"
                description="Get answers to frequently asked questions about listing and managing your African business on Find A Business Africa."
                keywords="FAQ, African businesses, business directory, Africa, list business, business tools"
            />

            <h1>FAQs for Business Owners</h1>

            <section className="faq-section">
                <h2>Why should I list my business on Find A Biz Africa?</h2>
                <p>Listing your business on our platform is completely <b>free</b> and gives you access to a wide range of benefits:</p>
                <ul>
                    <li><b>Increase visibility:</b> Reach a wider audience across the African continent.</li>
                    <li><b>Connect with customers:</b> Engage with potential customers and partners.</li>
                    <li><b>Access business tools:</b> Utilize our free toolkit for finance, marketing, and operations.</li>
                    <li><b>Gain valuable insights:</b> Get free business analytics reports to track your progress.</li>
                    <li><b>Stay informed:</b> Receive ongoing business tips and advice to help you grow.</li>
                </ul>
            </section>

            <section className="faq-section">
                <h2>How do I list my business?</h2>
                <ol>
                    <li>Sign up for a free account as a business owner.</li>
                    <li>Log in to your account and navigate to your Dashboard.</li>
                    <li>Click on "Register Business" and fill in your business details.</li>
                </ol>
            </section>

            <section className="faq-section">
                <h2>Can I update my business information?</h2>
                <p>Yes, you can easily update your business information from your Dashboard at any time.</p>
            </section>

            {/* New FAQ Section */}
            <section className="faq-section">
                <h2>What's the unique selling point that Find A Biz Africa provides that Instagram doesn't?</h2>
                <p>Find A Biz Africa offers several unique features tailored specifically for connecting customers with African businesses, which set us apart from platforms like Instagram:</p>
                <ul>
                    <li><b>Dedicated Business Directory:</b> Our platform is designed solely to help users discover and connect with African businesses through a comprehensive and searchable directory.</li>
                    <li><b>Targeted Visibility:</b> We focus on promoting businesses within the African continent, ensuring that your business gains visibility among a targeted audience looking for African products and services.</li>
                    <li><b>Business-Focused Analytics:</b> Our analytics provide insights tailored to business performance on our platform, helping you understand customer interactions and track engagement.</li>
                    <li><b>Localised Support and Community:</b> We offer localised support and resources, fostering a community of African entrepreneurs and business owners.</li>
                    <li><b>Sector-Specific Features:</b> Our platform includes tools and features tailored to the unique needs of different business sectors, such as service-based businesses and retail.</li>
                    <li><b>Professional Environment:</b> Unlike social media platforms, Find A Biz Africa is dedicated to business promotion and customer engagement, providing a focused environment for business growth.</li>
                </ul>
            </section>

            <section className="contact-section mt-4">
                <h2>Still have questions?</h2>
                <p>We're here to help! <Link to="/contact">Contact our support team</Link> for more information.</p>
            </section>
        </div>
    );
}

export default FAQs;