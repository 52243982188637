import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import '../App.css';

function Footer() {
    return (
        <footer className="footer mt-auto py-3 bg-light">
            <div className="container d-flex justify-content-between align-items-center">
                <span className="text-muted">
                    &copy; {new Date().getFullYear()} Find A Biz Africa. All rights reserved.
                </span>
                <div className="footer-links">
                    <Link to="/data-policy">Data Policy</Link>
                    <Link to="/terms-of-use">Terms of Use</Link>
                    <Link to="/contact">Contact Form</Link>
                    <a href="https://www.instagram.com/findabizafrica/" target="_blank" rel="noopener noreferrer" className="ml-3">
                        <FaInstagram size={20} />
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
