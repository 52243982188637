import React, { useState } from 'react';
import API_URL from '../config/config'; // Adjust the path as necessary

function VerifyOtp() {
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_URL}/api/verify-otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phone, otp })
            });
            const data = await response.json();
            alert(data.message);
        } catch (error) {
            console.error('Error verifying OTP:', error);
        }
    };

    return (
        <div className="container mt-5">
            <h2>Verify OTP</h2>
            <form onSubmit={handleVerifyOtp}>
                <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="otp" className="form-label">OTP</label>
                    <input
                        type="text"
                        className="form-control"
                        id="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Verify OTP</button>
            </form>
        </div>
    );
}

export default VerifyOtp;
