import React from 'react';
import BusinessListings from './BusinessListings';
import MetaTags from './MetaTags';

function Home() {
  return (
    <div className="container mt-5">
      <MetaTags
        title="Find A Biz Africa - Your African Business Directory"
        description="Discover and connect with African businesses. Find local services, products, and companies across the African continent."
        keywords="African businesses, business directory, Africa, find companies, local services, African products"
      />
      <h1 className="mb-4">Search below for products and services.</h1>
      <BusinessListings />
      
    </div>
  );
}

export default Home;
