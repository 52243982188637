import React from 'react';

const categories = [
  'Retail',
  'Service',
  'Food & Beverage',
  'Professional Services',
  'Trades',
  'Creative & Media',
  'Technology',
  'Health & Wellness',
  'Education',
  'Nonprofit',
  'Home Services',
  'Beauty & Personal Care',
  'Automotive',
  'Agriculture & Farming',
  'Handicrafts & Artisans',
  'Tourism & Hospitality',
  'Financial Services',
  'Transport & Logistics',
  'Other'
];

const subcategories = {
  'Retail': ['Clothing', 'Electronics', 'Home Goods', 'Books', 'Gifts', 'Specialty Food', 'Other'],
  'Service': ['Consulting', 'Cleaning', 'Repair', 'Financial Services', 'Legal Services', 'Marketing', 'Other'],
  'Food & Beverage': ['Restaurants', 'Cafes', 'Food Trucks', 'Bakeries', 'Bars', 'Other'],
  'Professional Services': ['Accounting', 'Law', 'Real Estate', 'Insurance', 'Other'],
  'Trades': ['Plumbing', 'Electrical', 'HVAC', 'Carpentry', 'Landscaping', 'Other'],
  'Creative & Media': ['Design', 'Photography', 'Writing', 'Music', 'Video', 'Other'],
  'Technology': ['Software Development', 'IT Support', 'Web Design', 'Other'],
  'Health & Wellness': ['Fitness', 'Yoga', 'Massage', 'Therapy', 'Nutrition', 'Other'],
  'Education': ['Tutoring', 'Training', 'Online Courses', 'Other'],
  'Nonprofit': ['Charitable Organizations', 'Community Groups', 'Other'],
  'Home Services': ['Cleaning', 'Handyman', 'Gardening', 'Home Organizing', 'Other'],
  'Beauty & Personal Care': ['Hair Salon', 'Nail Salon', 'Spa', 'Barber', 'Other'],
  'Automotive': ['Repair', 'Sales', 'Detailing', 'Other'],
  'Agriculture & Farming': ['Crop Farming', 'Livestock Farming', 'Agricultural Supplies', 'Other'],
  'Handicrafts & Artisans': ['Textiles', 'Woodwork', 'Jewelry', 'Pottery', 'Other'],
  'Tourism & Hospitality': ['Hotels', 'Travel Agencies', 'Tour Operators', 'Restaurants', 'Other'],
  'Financial Services': ['Banking', 'Microfinance', 'Mobile Money', 'Other'],
  'Transport & Logistics': ['Public Transport', 'Freight', 'Courier Services', 'Other'],
  'Other': ['Other'] // Added subcategory "Other" under "Other"
};

function CategoryDropdown({ selectedCategory, handleCategoryChange, selectedSubcategory, handleSubcategoryChange }) {
  return (
    <div className="form-group">
      <label htmlFor="category">Category:</label>
      <select
        id="category"
        name="category"
        className="form-control"
        value={selectedCategory}
        onChange={handleCategoryChange}
        required
      >
        <option value="" disabled>Select Category</option>
        {categories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
      {selectedCategory && selectedCategory in subcategories && (
        <>
          <label htmlFor="subcategory" className="mt-2">Subcategory:</label>
          <select
            id="subcategory"
            name="subcategory"
            className="form-control"
            value={selectedSubcategory}
            onChange={handleSubcategoryChange}
            required={subcategories[selectedCategory].length > 0}
          >
            <option value="" disabled>Select Subcategory</option>
            {subcategories[selectedCategory].map((subcategory) => (
              <option key={subcategory} value={subcategory}>
                {subcategory}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
}

export { categories, subcategories };
export default CategoryDropdown;
