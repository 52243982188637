import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function NavBar() {
    const { currentUser } = useAuth();
    const isLoggedIn = !!currentUser;

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link className="navbar-brand" to="/">FindABizAfrica</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about-us">About Us</Link> {/* Add About Us link */}
                        </li>
                        <li className="nav-item dropdown">
                            <button className="btn btn-link nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                Business Owners
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                {isLoggedIn ? (
                                    <>
                                        <li><Link className="dropdown-item" to="/dashboard">Dashboard</Link></li>
                                        <li><Link className="dropdown-item" to="/business-details">Register Business</Link></li>
                                        <li><Link className="dropdown-item" to="/businesstips">Business Tips</Link></li>
                                        <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                                    </>
                                ) : (
                                    <>
                                        <li><Link className="dropdown-item" to="/business-owner-portal">Login/Sign Up</Link></li>
                                        <li><Link className="dropdown-item" to="/faqs">FAQs</Link></li> {/* Corrected closing tag */}
                                        <li><Link className="dropdown-item" to="/request-password-reset">Forgot Password?</Link></li>
                                    </>
                                )}
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
