import React, { useState } from 'react';
import validator from 'validator'; 
import ReCAPTCHA from 'react-google-recaptcha';
import API_URL from '../config/config'; // Adjust the path as necessary

function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        recaptchaToken: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRecaptchaChange = (token) => {
        setFormData({ ...formData, recaptchaToken: token });
    };

    const validateForm = () => {
        if (!validator.isEmail(formData.email)) {
            return "Please enter a valid email address.";
        }
        if (formData.message.length < 10) {
            return "Message must be at least 10 characters long.";
        }
        if (!formData.recaptchaToken) {
            return "Please complete the reCAPTCHA.";
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        setError('');

        try {
            const response = await fetch(`${API_URL}/api/contact`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setSubmitted(true);
            } else {
                const data = await response.json();
                setError(data.message || 'Failed to send message');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message due to system error');
        }
    };

    return (
        <div className="container mt-5">
            <h2>Contact Us</h2>
            {submitted ? (
                <div className="alert alert-success" role="alert">
                    Thank you for contacting us! We will get back to you soon.
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <ReCAPTCHA
                        sitekey="6LeSdugpAAAAAMNxet7y_oMlCH4eKODgNVi2MViY" // Replace with your Site Key
                        onChange={handleRecaptchaChange}
                    />
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            )}
        </div>
    );
}

export default ContactForm;
