import React from 'react';

function AboutUs() {
  return (
    <div className="container mt-4">
      <h2>Find A Biz Africa: About Us</h2>
      <p>Want to reach a global audience? Find A Biz Africa can make it happen.</p>
      <p>
        At Find A Biz Africa, we're passionate about connecting the world with the incredible businesses that Africa has to offer. We understand the challenges faced by African entrepreneurs in getting their ventures seen by a wider (local and global) audience. That's why we created a platform designed specifically to address the issue of discoverability.
      </p>
      <h3>Your Gateway to the Global Marketplace</h3>
      <p>
        Find A Biz Africa is a comprehensive online business directory that makes it easier than ever for African businesses to showcase their products and services to a worldwide customer base. We offer a user-friendly platform with a focus on:
      </p>
      <ul>
        <li>
          <strong>Simple Listings:</strong> Our streamlined listing process allows you to quickly and easily create a detailed profile for your business, complete with essential information and captivating descriptions.
        </li>
        <li>
          <strong>Targeted Search:</strong> Our powerful search engine helps potential customers find exactly what they're looking for by location, category, and keyword.
        </li>
        <li>
          <strong>Increased Visibility:</strong> By listing your business on Find A Biz Africa, you gain valuable exposure to a vast audience of international users actively seeking African products and services.
        </li>
      </ul>
      <h3>More Than Just a Directory</h3>
      <p>
        We believe in the power of community. Find A Biz Africa is more than just a directory; it's a platform that connects African businesses with each other and fosters collaboration.
      </p>
      <h3>Join the Find A Biz Africa Community</h3>
      <p>
        By listing your business with us, you become part of a vibrant network of African entrepreneurs. We offer opportunities to:
      </p>
      <ul>
        <li>
          <strong>Network:</strong> Connect with other businesses in your industry and explore potential partnerships.
        </li>
        <li>
          <strong>Gain Insights:</strong> Access valuable resources and industry trends to help your business thrive.
        </li>
        <li>
          <strong>Grow Your Brand:</strong> Leverage the Find A Biz Africa platform to establish your brand as a leader in the African marketplace.
        </li>
      </ul>
      <h3>Find A Biz Africa: Your Success Story Starts Here</h3>
      <p>
        Let Find A Biz Africa be the bridge that connects your business to the world. Sign up today and take your venture to the next level!
      </p>
    </div>
  );
}

export default AboutUs;

