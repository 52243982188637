import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import NavBar from './components/NavBar';
import Home from './components/Home';
import BusinessListings from './components/BusinessListings';
import Login from './components/Login';
import Signup from './components/Signup';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';
import BusinessAnalytics from './components/BusinessAnalytics';
import BusinessOwnerPortal from './components/BusinessOwnerPortal';
import RequestOtp from './components/RequestOtp';
import VerifyOtp from './components/VerifyOtp';
import RequestPasswordReset from './components/RequestPasswordReset';
import ResetPassword from './components/ResetPassword';
import ContactForm from './components/ContactForm';
import DataPolicy from './components/DataPolicy';
import Footer from './components/Footer';
import BusinessTips from './components/BusinessTips';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import FAQs from './components/FAQs';
import PrivateRoute from './components/PrivateRoute';
import BusinessManager from './components/BusinessManager';
import TermsOfUse from './components/TermsOfUse';
import AboutUs from './components/AboutUs';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="app-container">
          <NavBar />
          <div className="content-wrap">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
              <Route path="/business/:id/analytics" element={<PrivateRoute element={BusinessAnalytics} />} />
              <Route path="/business-details" element={<BusinessManager />} />
              <Route path="/business-listings" element={<BusinessListings />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/business-owner-portal" element={<BusinessOwnerPortal />} />
              <Route path="/request-otp" element={<RequestOtp />} />
              <Route path="/verify-otp" element={<VerifyOtp />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/request-password-reset" element={<RequestPasswordReset />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/businesstips" element={<BusinessTips />} />
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/data-policy" element={<DataPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/about-us" element={<AboutUs />} /> 
              <Route path="/business/new" element={<PrivateRoute element={BusinessManager} />} />
              <Route path="/business/edit/:id" element={<PrivateRoute element={BusinessManager} />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
