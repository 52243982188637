import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import API_URL from '../config/config'; // Adjust the path as necessary

function ResetPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tokenFromUrl = urlParams.get('token');
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        } else {
            setError('Invalid or missing token.');
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Token:', token);
        console.log('New Password:', newPassword);
        try {
            const response = await fetch(`${API_URL}/api/reset-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token, newPassword })
            });
            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
                setError('');
                setTimeout(() => navigate('/login'), 3000); // Redirect to login page after 3 seconds
            } else {
                setError(data.message);
                setMessage('');
            }
        } catch (err) {
            setError('Failed to reset password.');
            setMessage('');
        }
    };

    return (
        <div className="container mt-5">
            <h2>Reset Password</h2>
            {message && <div className="alert alert-success">{message}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            {token && (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="newPassword" className="form-label">New Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Reset Password</button>
                </form>
            )}
        </div>
    );
}

export default ResetPassword;
