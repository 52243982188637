import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="container mt-5">
      <h2>Website Terms of Use</h2>
      
      <p><strong>BACKGROUND:</strong></p>
      <p>These Website Terms of Use, together with the documents referred to in them, set out the terms under which You may use our website at <a href="https://www.findabizafrica.com/">https://www.findabizafrica.com/</a> (Our Website).</p>

      <h3>1. Definitions and Interpretation</h3>
      <p>In these Website Terms of Use, the following expressions have the following meanings:</p>
      <ul>
        <li><strong>“Content”</strong> means any and all text, images, audio, video, scripts, code, software, databases and any other form of information that appears on, or forms part of, Our Website and/ or our social media channels (including but not limited to <a href="https://www.instagram.com/findabizafrica/">https://www.instagram.com/findabizafrica/</a>);</li>
        <li><strong>“Data Protection Legislation”</strong> means all applicable data protection and privacy legislation in force from time to time in the relevant African country where the business operates, including but not limited to the General Data Protection Regulation (GDPR) where applicable;</li>
        <li><strong>“Intellectual Property Rights”</strong> means:
          <ul>
            <li>(a) any and all rights in any patents, trademarks, service marks, registered designs, applications (and rights to apply for any of those rights), trade, business and company names, internet domain names and email addresses, unregistered trademarks and service marks, copyrights, database rights, know-how, rights in designs, inventions and processes;</li>
            <li>(b) rights under licences, consents, orders, statutes or otherwise in relation to a right in paragraph (a);</li>
            <li>(c) rights of the same or similar effect or nature as or to those in paragraphs (a) and (b) which now or in the future may subsist in any part of the world; and</li>
            <li>(d) the right to sue for past infringements of any of the foregoing rights;</li>
          </ul>
        </li>
        <li><strong>“We/Us/Our”</strong> means Find A Biz Africa of London, United Kingdom, as the owner and operator of Our Website; and</li>
        <li><strong>“You/ Your”</strong> means you, as a user of Our Website.</li>
      </ul>

      <h3>2. Acceptance of Terms</h3>
      <p>By accessing or using Our Website, you agree to comply with and be bound by these Website Terms of Use. If you do not agree to these terms, you should not use Our Website.</p>

      <h3>3. Changes to Terms</h3>
      <p>These Website Terms of Use may be revised from time to time without notice. Your continued use of our Website after any changes will constitute your acceptance of such changes. These Website Terms of Use were last updated on 18 Jun 2024.</p>

      <h3>4. Privacy Notice and Data Protection</h3>
      <p>4.1 Your use of Our Website is also governed by Our Privacy Notice, which can also be found on Our Website.</p>
      <p>4.2 We will collect, use and process Your personal data (including any personal data you supply when setting up Your Account) in accordance with our obligations under the Data Protection Legislation and Our Privacy Notice.</p>

      <h3>5. Account</h3>
      <p>5.1 You may need to create an Account to access some parts of Our Website. You must ensure You provide accurate information when creating Your Account, and keep that Account information up-to-date if it changes.</p>
      <p>5.2 You must create a strong password for Your Account. You must not share Your Account or password with anyone. You must not use anyone else’s Account.</p>
      <p>5.3 You must contact us immediately on support@findabizafrica.com if You believe anyone else is using Your Account.</p>
      <p>5.4 You may close Your Account at any time. However You will not then be able to access those areas of Our Website which require an Account to access them.</p>

      <h3>6. Intellectual Property</h3>
      <p>6.1 All Content on Our Website and Our social media accounts, including but not limited to text, graphics, logos, images, and software, is Our property (or the property of Our licensors) and is protected by copyright and other intellectual property laws.</p>
      <p>6.2 Save as set out in Clause 6.3, You may not use, reproduce, distribute, sell, sub-licence, store or create derivative works based on any Content from Our Website or Our social media accounts without Our prior written consent.</p>
      <p>6.3 You may access, view and use Our Website in a web browser, print pages from Our Website and save pages for later offline viewing.</p>
      <p>6.4 You must always acknowledge Us (or Our licensors, as appropriate) as the owner of the Content on Our Website and on Our social media channels.</p>

      <h3>7. User Conduct</h3>
      <p>7.1 You agree to use Our Website for lawful purposes only and not to engage in any activity that could harm Our Website, its users, or third parties.</p>
      <p>7.2 Whilst We endeavour to keep Our Website free from viruses and malware, You are responsible for protecting Your hardware, software and other materials from viruses, malware and other internet security risks.</p>
      <p>7.3 You may not:</p>
      <ul>
        <li>7.3.1 Use Our Website for any illegal or unauthorized purpose;</li>
        <li>7.3.2 Post or transmit any content that is (or could be construed as) harmful, offensive, discriminatory or violates any applicable laws;</li>
        <li>7.3.3 Deliberately introduce any virus or malware, or attack Our Website by means of a denial of service attack;</li>
        <li>7.3.4 Attempt to gain unauthorized access to Our Website or its systems; or</li>
        <li>7.3.5 Link to Our Website in a way which suggests any association between You and Us or any endorsement by Us, or in a way which might damage Our reputation or take unfair advantage of it.</li>
      </ul>
      <p>7.4 We reserve the right to suspect or terminate Your access to Your Account or to Our Website if You breach the terms of this Clause 7, or any other terms of these Website Terms of Use.</p>

      <h3>8. Links to Third-Party Websites</h3>
      <p>Our Website may contain links to third-party websites. We are not responsible for the content or these third-party websites, and shall not be liable in respect of any such content. We are not endorsing that content by linking to them. You should review the website terms and policies of any third-party websites you visit.</p>

      <h3>9. Disclaimers</h3>
      <p>9.1 We make no representations or warranties, express or implied, that Our Website will meet Your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software or hardware, or that it will be secure.</p>
      <p>9.2 We make no representations or warranties regarding the accuracy or completeness of the Content, or that the Content is up-to-date. We shall not be liable to You for any inaccuracy contained in the Content or for any reliance You may place on that Content.</p>

      <h3>10. Limitation of Liability</h3>
      <p>To the fullest extent permitted by law, We shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with Your use of Our Website. If You are a business owner, We shall not be liable for loss of profits, sales, business or revenue, loss of business opportunity, goodwill or reputation, loss of anticipated savings, business interruption or for any other indirect or consequential loss or damage.</p>

      <h3>11. Indemnification</h3>
      <p>You agree to indemnify and hold Us, and Our officers, directors, employees, and agents, harmless from any claims, liabilities, damages, and expenses (including legal fees) arising from Your use of Our Website or from Your violation of these Website Terms of Use.</p>

      <h3>12. Governing Law</h3>
      <p>These Website Terms of Use are governed by and construed in accordance with the laws of the country in which the business is registered. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts of that country.</p>

      <h3>13. Contact Information</h3>
      <p>If you have any questions or concerns about Our Website or these Website Terms of Use, please contact us at support@findabizafrica.com, or via any of the methods set out on Our ‘Contact Us’ page.</p>
    </div>
  );
};

export default TermsOfUse;
