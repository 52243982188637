import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import validator from 'validator';
import axiosInstance from '../axiosInstance';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const validateInput = () => {
    if (!validator.isEmail(email)) {
      return "Please enter a valid email address.";
    }
    if (!password) {
      return "Please enter your password.";
    }
    return '';
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationError = validateInput();
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      const response = await axiosInstance.post('/api/login', { email, password });
      if (response.status === 200) {
        login(response.data.authToken);
        console.log('handleSubmit: Login successful, authToken:', response.data.authToken);
        navigate('/dashboard');
      } else {
        setError(response.data.message || 'Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      if (error.response && error.response.status === 401) {
        setError('Incorrect password');
      } else if (error.response && error.response.status === 404) {
        setError('User not found');
      } else {
        setError('Login failed due to system error');
      }
    }
  };

  return (
    <div className="container mt-5 login">
      <h2>Login</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="form-group">
          <label>Email:</label>
          <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} required />
        </div>
        <button type="submit" className="btn btn-primary mt-4">Log In</button>
      </form>
      <div className="mt-3">
        <Link to="/request-password-reset">Forgot Password?</Link>
      </div>
    </div>
  );
}

export default Login;
