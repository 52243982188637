import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, keywords }) => (
  <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <title>{title}</title>
    <link rel="canonical" href="https://www.findabizafrica.com/" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content="https://www.findabizafrica.com/" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.findabizafrica.com/findabiz.png" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content="https://www.findabizafrica.com/findabiz.png" />
  </Helmet>
);

export default MetaTags;
